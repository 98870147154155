<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" md="8">
        <v-card :loading="loader" class>
          <v-form v-model="valid" ref="form">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Сброс пароля</v-toolbar-title>
            </v-toolbar>

            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="email"
                    label="E-mail"
                    prepend-icon="$vuetify.icons.account"
                    :rules="emailRules"
                    required
                  ></v-text-field>
                  <v-btn dark color="primary" @click="submit">Отправить</v-btn>
                </v-col>
           
              </v-row>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    valid: true,
    loading: false,
    email: "",
    // remember: false,
    requiredRules: [v => !!v || "Поле обязательно"],
    emailRules: [
      v => !!v || "Поле обязательно",
      v => /.+@.+\..+/.test(v) || "Не верно введен E-mail"
    ]
  }),

  methods: {
    validate() {
      return this.$refs.form.validate();
    },

    submit() {
      event.preventDefault();
      if (!this.validate()) return;
      this.loading = true;
      this.$store
        .dispatch("forgotPassword", {
          email: this.email,
        })
        .then(() => {
          this.loading = false;
          // console.log(responce);
        })
        .catch(() => {
          this.loading = false;
   
        });
    },

    clear() {
      this.email = "";
    }
  },
  computed: {
    loader() {
      if (this.loading) {
        return "cyan ";
      }
      return false;
    }
  }
};
</script>
